/* App.css */
body {
  margin: 0;
  padding: 0;
  background: url('./assets/map.png') no-repeat center center fixed;
  background-size: cover;
  font-family: Arial, sans-serif;
  height: 100vh;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.content {
  text-align: center;
  padding: 100px;
  color: white;
}